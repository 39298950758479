import { useCallback } from "react";

import SelectSearchDropDown from "components/Common/FilterSelects/SelectSearchDropDown";
import SingleSelectionList from "components/Common/FilterSelects/SingleSelectionList";

export default function SearchSingleSelectFilter(props) {
  const {
    filter,
    selected,
    onChange,
    label,
    topLabel,
    ariaLabel,
    defaultSelectedId,
  } = props;

  const handleClick = useCallback(
    (value, closer) => {
      onChange && onChange(value);
      closer && closer();
    },
    [onChange]
  );

  return (
    <SelectSearchDropDown
      selected={selected}
      filter={filter}
      topLabel={topLabel}
      ariaLabel={ariaLabel}
      label={label}
      RenderList={SingleSelectionList}
      defaultSelectedId={defaultSelectedId}
      handleClick={handleClick}
    />
  );
}
