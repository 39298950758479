import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, Fragment } from "react";

import getListPageHeading from "utils/getListPageHeading";
import getSort from "utils/lists/getSort";

import useList, { useShowFiltersHeading } from "hooks/useList";
import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  filterHeading: {
    ...gStyles.avalonMedium,
    fontSize: "75%",
    marginTop: ".75rem",
  },
};

const ListSortHeader = (props) => {
  const {
    listKey,
    heading,
    format,
    children,
    overrideSortTitles,
    filterHeading,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  const showFiltersHeading = useShowFiltersHeading(listKey);

  const {
    direction: sortDirection,
    sort: pulledSort,
    filters,
    availableFilters,
  } = useList(listKey);

  const sort = getSort(pulledSort);

  const mainContent = (
    <Fragment>
      {getListPageHeading({
        ...props,
        sort,
        sortDirection,
        filters,
        availableFilters,
        format: format || heading || "%sort% %type%",
        overrideSortTitles,
      })}{" "}
      {children}
    </Fragment>
  );

  if (filterHeading && showFiltersHeading) {
    return (
      <div>
        {mainContent}
        <div className={css(styles.filterHeading)}>
          {getListPageHeading({
            ...props,
            sort,
            sortDirection,
            filters,
            availableFilters,
            format: filterHeading,
          })}
        </div>
      </div>
    );
  }

  return mainContent;
};

ListSortHeader.propTypes = {
  listKey: PropTypes.string.isRequired,
  children: PropTypes.node,
  heading: PropTypes.string,
  filterHeading: PropTypes.string,
  format: PropTypes.string,
  overrideSortTitles: PropTypes.object,
};
ListSortHeader.defaultProps = {
  children: null,
  heading: null,
  filterHeading: null,
  format: null,
  overrideSortTitles: null,
};

export default memo(ListSortHeader);
