import React from "react";

import StandardButton from "components/Buttons/StandardButton";

import useOutsideClickHandler from "hooks/useOutsideClickHandler";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  columnManContainer: {
    position: "relative",
  },
  modalContainer: {
    display: "grid",
    gridTemplateRows: "max-content 1fr",
    gridGap: "0.25rem",
    background: colours.white,
    position: "fixed",
    padding: "1.5rem 1.5rem 4rem 1.5rem",
    border: `1px solid ${colours.borderGrey}`,
    borderRadius: ".5rem",
    boxShadow: "0 0.313rem 1rem 0 rgba(0,0,0,0.07)",
    zIndex: 10,
    bottom: 0,
    left: 0,
    width: "100%",

    [ScreenSizes.mdAndAbove]: {
      position: "absolute",
      padding: "1rem",
      marginTop: 5,
      width: 320,
      marginLeft: -125,
      bottom: "auto",
    },
  },
  topContainer: {
    display: "grid",
    gridTemplateColumns: "1fr max-content max-content",
    gridGap: "0.5rem",
    alignItems: "center",
  },
  headerButton: {
    border: "none",
    outline: "none",
    color: colours.checkboxSelected,
    ...gStyles.fontRegular,
    fontSize: "1rem",
    width: "max-content",
    padding: "0.2rem",

    ":hover": {
      cursor: "pointer",
    },
  },
  header: {
    ...gStyles.avalonBold,
    color: colours.black,
    fontSize: "1rem",
    margin: 0,
  },
};

const buttonStyles = {
  button: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    paddingTop: "0.5rem",
    paddingBottom: 7,
  },
};

export default function ColumnManagement(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const {
    hideAllHandleClick,
    showAllHandleClick,
    showColumnManagement,
    setShowColumnManagement,
    children,
  } = props;

  const wrapperRef = useOutsideClickHandler(() =>
    setShowColumnManagement(false)
  );

  return (
    <div className={css(styles.columnManContainer)} ref={wrapperRef}>
      {showColumnManagement && (
        <div className={css(styles.modalContainer)}>
          <div className={css(styles.topContainer)}>
            <h3 className={css(styles.header)}>Columns</h3>
            <StandardButton
              onClick={hideAllHandleClick}
              variation="white"
              label="Hide All"
              width="max-content"
              flat
              withPadding
              customStyles={buttonStyles}
            />
            <StandardButton
              onClick={showAllHandleClick}
              variation="white"
              label="Show All"
              width="max-content"
              flat
              withPadding
              customStyles={buttonStyles}
            />
          </div>
          <div className={css(styles.listContainer)}>{children}</div>
        </div>
      )}
    </div>
  );
}
