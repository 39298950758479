import { useCallback, useEffect } from "react";

import paginationActions from "actions/pagination";
import { selectSearchTerm } from "selectors/pagination";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useReduxState from "hooks/useReduxState";

function useSearchTerm(props) {
  const { listKey, eventContext } = props;

  const { setSearchTerm } = useActionCreators(paginationActions);

  const term = useReduxState(
    (state) => selectSearchTerm(state, { key: listKey }),
    [listKey]
  );

  useEffect(() => {
    return () => {
      if (term !== "") {
        setSearchTerm(listKey, "");
      }
    };
  }, [setSearchTerm, listKey, term]);

  const onSearchTermRequest = useCallback(() => {
    sendGAEvent({
      action: "PodcastSearchClick",
      term,
      ...eventContext,
    });
  }, [term, eventContext]);

  const handleChange = useCallback(
    (value) => setSearchTerm(listKey, value, onSearchTermRequest),
    [setSearchTerm, listKey, onSearchTermRequest]
  );

  return { term, handleChange };
}

export default useSearchTerm;
