import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";

import SortSelect from "./SortSelect";

import paginationActions from "actions/pagination";
import * as sortConstants from "constants/sort";
import { selectListSort, selectListSortDirection } from "selectors/pagination";
import { equals } from "utils/misc";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";

import ScreenSizes from "styles/ScreenSizes";

const { SORT_ORDER_RANKING, sortIcons, RANKING_SORTS } = sortConstants;

const baseStyles = {
  controls: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "left",
    lineHeight: "1",
    fontSize: "0.7em",
  },
  control: {
    width: "100%",
    minWidth: 110,
    maxWidth: 260,

    [ScreenSizes.mdAndBelow]: {
      maxWidth: "none",
      marginBottom: 10,
    },
    marginRight: 20,
    ":last-of-type": {
      marginRight: 0,
    },
  },
};

const RANKING_SORT_KEYS = [SORT_ORDER_RANKING];

const ListSort = (props) => {
  const {
    listKey,
    overrideSortTitles,
    include,
    entity_type,
    sortEventTracking,
  } = props;

  const { styles } = useStyles(baseStyles, props);

  const { setSort, removeFilter } = useActionCreators({
    setSort: paginationActions.setSort,
    removeFilter: paginationActions.removeFilter,
  });

  const sortSelectStyles = {
    ...props?.styles,
    icon: {
      fontSize: ".75rem",
      marginLeft: ".1rem",
    },
  };

  const activeSort = useReduxState(
    (state) =>
      RANKING_SORT_KEYS.includes(selectListSort(state, listKey))
        ? SORT_ORDER_RANKING
        : selectListSort(state, listKey),
    [listKey]
  );
  const activeDirection = useReduxState(
    (state) => selectListSortDirection(state, listKey),
    [listKey]
  );

  const handleClick = useCallback(
    (sort, direction) => {
      setSort({
        key: listKey,
        sort,
        direction,
        dontReloadList: true,
      });
      sendGAEvent({
        action: "SortEntityButtonClicked",
        entity: entity_type,
        ...sortEventTracking,
      });
      removeFilter(listKey, "ranking_period");
    },
    [setSort, listKey, entity_type, sortEventTracking, removeFilter]
  );

  const sortOptions = useMemo(
    () =>
      include &&
      include.map((id) => {
        const isRankingSort = RANKING_SORTS.includes(id);

        let foundSort = sortIcons.find((sort) => {
          if (isRankingSort) {
            return sort.id === SORT_ORDER_RANKING;
          }

          return equals(sort.id, id);
        });

        if (isRankingSort) {
          foundSort = { ...foundSort, id };
        }

        return {
          ...foundSort,
          title:
            (typeof overrideSortTitles[id] === "object"
              ? overrideSortTitles[id][activeDirection || "desc"]
              : overrideSortTitles[id]) || foundSort.title,
        };
      }),
    [activeDirection, include, overrideSortTitles]
  );

  // TODO: Need to add the sort and the filter selection as list items to a container so the same styles can be applied to both (widths, floats, etc)

  return (
    <div
      data-id="list-sort"
      className={css(styles.controls, styles.control, styles.sorts)}
    >
      <SortSelect
        dataId="sort-options"
        sortOptions={sortOptions}
        selected={activeSort}
        direction={activeDirection}
        onChange={handleClick}
        ariaLabel="Choose List sort"
        styles={sortSelectStyles}
      />
    </div>
  );
};

ListSort.propTypes = {
  listKey: PropTypes.string,
  include: PropTypes.array,
  overrideSortTitles: PropTypes.object,
};

ListSort.defaultProps = {
  listKey: null,
  include: [sortConstants.SORT_ORDER_RECENT, sortConstants.SORT_ORDER_RANKING],
  overrideSortTitles: {},
};

export default memo(ListSort);
