import { getYear } from "date-fns/getYear";

import * as sortConstants from "constants/sort";
import cleanEntityType from "utils/entity/cleanEntityType";
import getSortTitle, { getFilterTitles } from "utils/getSortTitle";

// TODO: Make this a generic function that we can pass an object
// of values to, that get replaced in the string when they match.
// Format values like singular, short, etc should be optional and
// not hardcoded like they are below. Use this when passing strings
// to objects in FilteredEntityList or other places that use entities,
// so we can mention the entity type in the string and it gets replaced,
// in the format we need it (lowercase, short, etc)
export default function getListPageHeading({
  format: passedFormat,
  type,
  subtype,
  sort,
  ignoreKeys,
  sortDirection,
  overrideSortTitles,
  year,
  filters,
  availableFilters,
  // heading,
}) {
  const usableYear = `${year || getYear(new Date())}`;
  const format =
    sort === sortConstants.SORT_ORDER_POWER_SCORE
      ? passedFormat.replace("%sort%", "By Power Score -")
      : passedFormat;

  return format
    .replace(
      "%sort%",
      getSortTitle({
        sort,
        ignoreKeys,
        sortDirection,
        overrideSortTitles,
      })
    )
    .replace("%year%", usableYear)
    .replace("%type%", type ? cleanEntityType(type, true) : "")
    .replace("%type.short%", type ? cleanEntityType(type, true, true) : "")
    .replace("%type.singular%", type ? cleanEntityType(type, false) : "")
    .replace(
      "%type.singular.short%",
      type ? cleanEntityType(type, false, true) : ""
    )
    .replace("%subtype%", subtype ? cleanEntityType(subtype, true) : "")
    .replace(
      "%subtype.short%",
      subtype ? cleanEntityType(subtype, true, true) : ""
    )
    .replace(
      "%subtype.singular%",
      subtype ? cleanEntityType(subtype, false) : ""
    )
    .replace("%filters%", getFilterTitles(filters, availableFilters));
}
