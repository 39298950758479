import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faSortAmountDown } from "@fortawesome/free-solid-svg-icons/faSortAmountDown";
import { faSortAmountDownAlt } from "@fortawesome/free-solid-svg-icons/faSortAmountDownAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Downshift from "downshift";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";

import { baseStyles as selectBaseStyles } from "./SelectStyles";

import { RANKING_SORTS } from "constants/sort";
import { indexKey } from "utils/misc";
import eventIsFieldTrigger from "utils/misc/eventIsFieldTrigger";

import { useStyles } from "hooks/useStyles";

const sortIcons = {
  alpha: {
    asc: faSortAmountDownAlt,
    desc: faSortAmountDown,
  },
  amount: {
    asc: faSortAmountDownAlt,
    desc: faSortAmountDown,
  },
  numeric: {
    asc: faSortAmountDownAlt,
    desc: faSortAmountDown,
  },
};

const baseStyles = {
  ...selectBaseStyles,
  selectedItemButton: {
    border: "none",
    padding: 0,
  },
};

function SortSelect(props) {
  const { sortOptions, selected, direction, onChange, ariaLabel, dataId } =
    props;

  const { styles, css } = useStyles(baseStyles, props);

  const selectedSort = useMemo(
    () =>
      sortOptions &&
      sortOptions.find((s) => {
        if (RANKING_SORTS.includes(selected)) {
          return RANKING_SORTS.includes(s.id);
        }

        return s.id === selected;
      }),
    [selected, sortOptions]
  );

  const sortDirectionEnabled = selectedSort && !!selectedSort.defaultDirection;

  const sortIcon =
    sortIcons[
      selectedSort && selectedSort.directionIcon
        ? selectedSort.directionIcon
        : "alpha"
    ][direction || "desc"]; // TODO: Share with other file

  const handleChange = useCallback(
    (newSort) => {
      if (!newSort) {
        return null;
      }
      const sortOption = sortOptions.find((s) => s.id === newSort.id);
      onChange(newSort.id, sortOption && sortOption.defaultDirection);
    },
    [onChange, sortOptions]
  );

  const toggleSortDirection = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (!sortDirectionEnabled) {
        return;
      }
      let newDirection = "asc";
      if (direction === "asc") {
        newDirection = "desc";
      }

      onChange(selected, newDirection);
      return false;
    },
    [direction, onChange, selected, sortDirectionEnabled]
  );

  const hasChanged = useCallback(
    (prev, next) => !prev || !next || prev.id !== next.id,
    []
  );

  return (
    <Downshift
      selectedItem={selectedSort}
      selectedItemChanged={hasChanged}
      onChange={handleChange}
      itemToString={(sort) => sort && sort.title}
    >
      {({
        getItemProps,
        getMenuProps,
        getToggleButtonProps,
        isOpen,
        highlightedIndex,
        selectedItem,
        getRootProps,
      }) => (
        <h2
          data-id="sort-select"
          className={css(styles.outer)}
          aria-label={ariaLabel}
        >
          <div>
            <div {...getRootProps({}, { suppressRefError: true })}>
              <div className={css(styles.input)}>
                <button
                  data-id={dataId}
                  aria-label={ariaLabel}
                  className={css(styles.selectedItemButton)}
                  {...(getToggleButtonProps && getToggleButtonProps())}
                >
                  {selectedItem ? (
                    <span
                      data-id="selected-sort-option"
                      className={css(styles.content)}
                    >
                      {selectedItem.icon && (
                        <FontAwesomeIcon
                          icon={selectedItem.icon}
                          fixedWidth
                          className={css(styles.icon)}
                        />
                      )}
                      <span
                        data-id="selected-sort-option-label"
                        className={css(styles.text)}
                      >
                        {selectedItem.title}
                      </span>
                    </span>
                  ) : (
                    <span className={css(styles.content, styles.text)}>
                      Sort By
                    </span>
                  )}
                </button>
                <div className={css(styles.sideButtons)}>
                  {sortDirectionEnabled && (
                    <button
                      data-id="sort-select-direction"
                      onClick={toggleSortDirection}
                      onKeyDown={(e) =>
                        eventIsFieldTrigger(e) && toggleSortDirection(e)
                      }
                      className={css(styles.sideButton, styles.sideButtonLink)}
                      aria-label="Sort Direction"
                    >
                      <FontAwesomeIcon
                        icon={sortIcon}
                        data-id={`sort-select-direction-${direction || "desc"}`}
                        className={css(
                          isOpen ? styles.openSideIcon : styles.sideIcon
                        )}
                      />
                    </button>
                  )}
                  <button
                    className={css(styles.sideButton)}
                    {...(getToggleButtonProps && getToggleButtonProps())}
                  >
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className={css(
                        isOpen ? styles.openToggleIcon : styles.toggleIcon
                      )}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ul {...getMenuProps()}>
            {isOpen && (
              <div className={css(styles.options)}>
                {sortOptions.map((option, i) => {
                  const selected =
                    selectedItem && option.id === selectedItem.id;
                  const highlighted = highlightedIndex === i;

                  return (
                    <div
                      {...getItemProps({ item: option })}
                      data-id="sort-option"
                      className={css(
                        styles.option,
                        highlighted && styles.highlightedOption,
                        selected && styles.selectedOption,
                        selected &&
                          highlighted &&
                          styles.selectedHighlightedOption,
                        styles.content
                      )}
                      key={indexKey(`sortSelect${i}`)}
                    >
                      {option.icon && !option.hideDropdownIcon && (
                        <span className={css(styles.icon)}>
                          <FontAwesomeIcon icon={option.icon} fixedWidth />
                        </span>
                      )}
                      <span
                        data-id={`sort-option-${option.title.toLowerCase()}`}
                        className={css(styles.text)}
                      >
                        {option.title}
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          </ul>
        </h2>
      )}
    </Downshift>
  );
}

SortSelect.propTypes = {
  sortOptions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string,
  direction: PropTypes.string,
  dataId: PropTypes.string,
  ariaLabel: PropTypes.string,
  styles: PropTypes.object,
};
SortSelect.defaultProps = {
  dataId: null,
  selected: undefined,
  direction: "desc",
  ariaLabel: undefined,
  styles: null,
};

export default memo(SortSelect);
