import {
  SORT_ORDER_RANKING,
  SORT_ORDER_RANKING_WEEK,
  SORT_ORDER_RANKING_MONTH,
  SORT_ORDER_RANKING_YEAR,
} from "constants/sort";

const RANKING_SORT_KEYS = [
  SORT_ORDER_RANKING,
  SORT_ORDER_RANKING_WEEK,
  SORT_ORDER_RANKING_MONTH,
  SORT_ORDER_RANKING_YEAR,
];

export default function getSort(sort) {
  if (RANKING_SORT_KEYS.includes(sort)) {
    return SORT_ORDER_RANKING;
  }

  return sort;
}
