import { faSlidersH } from "@fortawesome/free-solid-svg-icons/faSlidersH";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css, StyleSheet } from "aphrodite";
import { Map, List } from "immutable";
import PropTypes from "prop-types";
import { memo, Fragment, useCallback } from "react";

import StandardButton from "../Buttons/StandardButton";

import eventIsFieldTrigger from "utils/misc/eventIsFieldTrigger";
import { getValueTitle, getActiveFilters } from "utils/search";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  filterButton: {
    ...gStyles.headerFilterButton,
    minWidth: "5.99rem",
  },
  filterButtonContent: {
    ...gStyles.headerFilterButtonContent,
  },
  filterButtonIcon: {
    ...gStyles.headerFilterButtonIcon,
  },
  filterButtonText: {
    ...gStyles.headerFilterButtonLabel,
  },
  filterButtonActive: {
    background: "var(--color-selected-d95)",
    color: colours.darkishBlue,
  },
  filterCount: {
    background: colours.darkishBlue,
    color: colours.white,
    borderRadius: 6,
    display: "inline-block",
    padding: "0.3em 0.5em 0.2em",
    fontWeight: "bold",
    marginLeft: "0.5em",
    flex: "0 0 auto",
    justifySelf: "flex-end",
  },
  filters: {
    margin: "0 0 2em",
    position: "relative",
  },
  filterContainer: {
    ...gStyles.contentBox,
    padding: "1em",
    display: "block",
  },
  filterRender: {
    display: "block",
  },
  filter: {
    display: "block",
  },
  filterName: {
    ...gStyles.avalonBold,
    display: "block",
    fontSize: "1rem",
    marginBottom: "0.2em",
  },
  filterValue: {
    display: "block",
    marginBottom: "0.5em",
    fontSize: "0.8125rem",
  },
  filterRemoveButton: {
    ...gStyles.fontBold,
    background: "#eee",
    color: "var(--color-neutral-d4)",
    border: "none",
    appearance: "normal",
    fontSize: "0.75rem",
    textAlign: "center",
    lineHeight: "1",
    display: "block",
    textTransform: "uppercase",
    cursor: "pointer",
    borderRadius: 4,
    marginLeft: "auto",
    width: "auto",
    padding: "0.3125rem 1rem",
    ":hover": {
      background: "#ddd",
      color: "#444",
    },
  },
  sidebarTitle: {
    ...gStyles.bodySubHeading,
    fontSize: "0.75rem",
    margin: "0 0 7px 0",
    padding: 0,
  },
  singleContent: {
    minWidth: "max-content",
    flex: 1,
    padding: 0,
    height: "2rem",
    fontSize: "0.7rem",
  },
};

const buttonStyles = {
  button: {
    marginLeft: "auto",
    width: "50%",
    marginRight: 0,
    padding: "0.25rem",
  },
};

const FiltersDisplay = (props) => {
  const {
    filters,
    showFilters,
    showButton,
    showIcon,
    customLabel,
    removeButtonText,
    allFilters,
    availableFilters,
    onAddFiltersClick,
    onRemoveFilter,
  } = props;

  const { css, styles } = useStyles(baseStyles, props);
  const activeFilters = getActiveFilters(filters, availableFilters);
  const hasFilters = activeFilters && activeFilters.size > 0;

  const handleRemoveFilter = useCallback(
    (key) => () => onRemoveFilter(key),
    [onRemoveFilter]
  );

  const renderFilter = (key, filter) => {
    switch (availableFilters.getIn([key, "type"])) {
      case "multiselect": {
        if (!allFilters) {
          return null;
        }
        return (
          <span className={css(styles.filter)}>
            <span className={css(styles.filterName)}>
              {allFilters.getIn([key, "title"])}
            </span>{" "}
            <span className={css(styles.filterValues)}>
              {(!allFilters.getIn([key, "options"]) ||
                allFilters.getIn([key, "options"]).size === 0) && (
                <span className={css(styles.filterValue)}>
                  No options with current filter.
                </span>
              )}
              {filter
                .get("value")
                .valueSeq()
                .map((value) => (
                  <span key={value} className={css(styles.filterValue)}>
                    {getValueTitle(allFilters, key, value)}
                  </span>
                ))}
            </span>
          </span>
        );
      }
      case "date_range": {
        return (
          <span className={css(styles.filter)}>
            <span className={css(styles.filterName)}>
              {availableFilters.getIn([key, "title"])}
            </span>{" "}
            <span className={css(styles.filterValue)}>
              From {filter.getIn(["value", "from"])} to{" "}
              {filter.getIn(["value", "to"])}
            </span>
          </span>
        );
      }
      case "range": {
        return (
          <span className={css(styles.filter)}>
            <span className={css(styles.filterName)}>
              {availableFilters.getIn([key, "title"])}
            </span>{" "}
            <span className={css(styles.filterValue)}>
              From {filter.getIn(["value", "0"])} to{" "}
              {filter.getIn(["value", "1"])}
            </span>
          </span>
        );
      }
      case "text": {
        return (
          <span className={css(styles.filter)}>
            <span className={css(styles.filterName)}>
              {availableFilters.getIn([key, "title"])}
            </span>{" "}
            <span className={css(styles.filterValue)}>
              Keyword:
              {filter.get("value")}
            </span>
          </span>
        );
      }
      case "number": {
        return (
          <span className={css(styles.filter)}>
            <span className={css(styles.filterName)}>
              {availableFilters.getIn([key, "title"])}
            </span>{" "}
            <span className={css(styles.filterValue)}>
              {availableFilters.getIn([key, "prefix"]) && (
                <span>{availableFilters.getIn([key, "prefix"])} </span>
              )}
              {filter.get("value")}
              {availableFilters.getIn([key, "suffix"]) && (
                <span> {availableFilters.getIn([key, "suffix"])}</span>
              )}
            </span>
          </span>
        );
      }
      default:
        return null;
    }
  };

  return (
    <div className={css(styles.outer1)}>
      {showButton && (
        <button
          data-id="add-filters-button"
          type="button"
          onClick={onAddFiltersClick}
          onKeyDown={(e) => eventIsFieldTrigger(e) && onAddFiltersClick(e)}
          className={css(
            styles.filterButton,
            hasFilters && styles.filterButtonActive,
            !showIcon && styles.singleContent
          )}
        >
          <span className={css(styles.filterButtonContent)}>
            {showIcon && (
              <span className={css(styles.filterButtonIcon)}>
                <FontAwesomeIcon icon={faSlidersH} />
              </span>
            )}
            <span className={css(styles.filterButtonText)}>
              {customLabel || "Filters"}
            </span>
            {hasFilters && (
              <span className={css(styles.filterCount)}>
                {activeFilters.size}
              </span>
            )}
          </span>
        </button>
      )}
      {showFilters && hasFilters && (
        <Fragment>
          <h2 className={css(styles.sidebarTitle)}>
            {customLabel || "Filters"}
          </h2>
          <div className={css(styles.filters)}>
            {filters.keySeq().map((key) => (
              <div className={css(styles.filterContainer)} key={key}>
                <div className={css(styles.filterRender)}>
                  {renderFilter(key, filters.get(key))}
                </div>
                <button
                  type="button"
                  onClick={handleRemoveFilter(key)}
                  className={css(styles.filterRemoveButton)}
                >
                  {removeButtonText}
                </button>
              </div>
            ))}
          </div>
          <StandardButton
            label="Open Filters"
            variation="pink"
            onClick={onAddFiltersClick}
            onKeyDown={(e) => eventIsFieldTrigger(e) && onAddFiltersClick(e)}
            customStyles={buttonStyles}
          />
        </Fragment>
      )}
    </div>
  );
};

FiltersDisplay.propTypes = {
  filters: PropTypes.object,
  availableFilters: PropTypes.instanceOf(Map),
  onAddFiltersClick: PropTypes.func,
  onRemoveFilter: PropTypes.func,
  showFilters: PropTypes.bool,
  showButton: PropTypes.bool,
  showIcon: PropTypes.bool,
  customLabel: PropTypes.string,
  removeButtonText: PropTypes.node,
  allFilters: PropTypes.oneOfType([
    PropTypes.instanceOf(List),
    PropTypes.instanceOf(Map),
  ]),
};
FiltersDisplay.defaultProps = {
  filters: {},
  showButton: true,
  showIcon: true,
  showFilters: false,
  removeButtonText: "x",
  customLabel: "",
  availableFilters: null,
  onAddFiltersClick: null,
  onRemoveFilter: null,
  allFilters: null,
};

export default memo(FiltersDisplay);
