import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";
import PropTypes from "prop-types";
import merge from "ramda/src/mergeDeepRight";
import { memo, useCallback, useEffect, useState, useMemo } from "react";

import {
  SORT_ORDER_RANKING,
  SORT_ORDER_RANKING_MONTH,
  SORT_ORDER_RANKING_WEEK,
  SORT_ORDER_RANKING_YEAR,
} from "../../constants/sort";
import SortSelect from "./SortSelect";

import paginationActions from "actions/pagination";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useList from "hooks/useList";
import useQuery from "hooks/useQuery";
import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  controls: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "left",
    lineHeight: "1",
    fontSize: "0.7em",

    [ScreenSizes.mdAndBelow]: {
      width: "100%",
    },
  },
  control: {
    width: "100%",
    maxWidth: 260,

    [ScreenSizes.mdAndBelow]: {
      maxWidth: "none",
      marginBottom: 10,
    },
    marginRight: 20,
    ":last-of-type": {
      marginRight: 0,
    },
  },
  label: {
    ...gStyles.label,
    fontSize: "0.6em",
    fontWeight: 200,
    marginRight: "0.4em",
  },
};

const INPUT_WIDTH = "7rem";

const DEFAULT_STYLES = {
  input: {
    minWidth: INPUT_WIDTH,
  },
  options: {
    minWidth: INPUT_WIDTH,
  },
  option: {
    minWidth: INPUT_WIDTH,
  },
  text: {},
  icon: {
    fontSize: "0.75rem",
    marginLeft: ".1rem",
  },
};

const RANGES = [
  {
    id: "week",
    title: "This Week",
    value: SORT_ORDER_RANKING_WEEK,
    icon: faClock,
    hideDropdownIcon: true,
  },
  {
    id: "month",
    title: "This Month",
    value: SORT_ORDER_RANKING_MONTH,
    icon: faClock,
    hideDropdownIcon: true,
  },
  {
    id: "year",
    title: "This Year",
    value: SORT_ORDER_RANKING_YEAR,
    icon: faClock,
    hideDropdownIcon: true,
  },
  {
    id: "all",
    title: "All Time",
    value: SORT_ORDER_RANKING,
    icon: faClock,
    hideDropdownIcon: true,
  },
];

const ListDateRangeSelect = (props) => {
  const { listKey } = props;

  const { styles, css } = useStyles(baseStyles, props);

  const sortSelectStyles = useMemo(
    () => merge(DEFAULT_STYLES, props?.styles || {}),
    [props?.styles]
  );

  const [query, setQuery] = useQuery();

  const defaultRange = (query && query.ranking_period) || "month";

  const [selectedItem, setSelectedItem] = useState(
    RANGES.find((r) => r.id === defaultRange)
  );

  const { list } = useList(listKey);

  const { setSort, removeFilter } = useActionCreators({
    setSort: paginationActions.setSort,
    removeFilter: paginationActions.removeFilter,
  });

  useEffect(() => {
    if (
      defaultRange !== "all" &&
      defaultRange !== null &&
      defaultRange !== list.getIn(["staticFilters", "ranking_period", "value"])
    ) {
      const defaultSort = RANGES.find((sort) => sort.id === defaultRange);
      const newSort = defaultSort
        ? defaultSort.value
        : SORT_ORDER_RANKING_MONTH;

      if (list.get("sort") !== newSort) {
        setSort({
          key: listKey,
          sort: newSort,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // only do on mount

  const handleChange = useCallback(
    (id) => {
      const option = RANGES.find((op) => op.id === id);

      setSelectedItem(option);

      sendGAEvent({
        action: "listDateRangeSelected",
        selected: option.value,
        listKey,
      });

      if (option.value === null) {
        removeFilter(listKey, "ranking_period");
      } else {
        setSort({
          key: listKey,
          sort: option.value,
        });

        setQuery({
          ...query,
          ranking_period: option.id,
        });
      }
    },
    [listKey, removeFilter, setSort, setQuery, query]
  );

  return (
    <div
      data-id="date-range-select"
      className={css(styles.controls, styles.control, styles.sorts)}
    >
      <SortSelect
        styles={sortSelectStyles}
        sortOptions={RANGES}
        selected={selectedItem.id}
        onChange={handleChange}
        widthAuto
        ariaLabel="Select Date Range"
      />
    </div>
  );
};

ListDateRangeSelect.propTypes = {
  listKey: PropTypes.string,
};

ListDateRangeSelect.defaultProps = {
  listKey: null,
};

export default memo(ListDateRangeSelect);
