import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, useCallback, useState } from "react";

import accessibleClickProps from "utils/misc/accessibleClickProps";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";

const baseStyles = {
  hiddenCheckBox: {
    // Hide checkbox visually but remain accessible to screen readers.
    // Source: https://polished.js.org/docs/#hidevisually
    border: "0",
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: "1px",
    margin: "-1px",
    overflow: "hidden",
    padding: "0",
    position: "absolute",
    whiteSpace: "nowrap",
    width: "1px",
  },
  styledCheckBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "1.625rem",
    height: "1.625rem",
    background: colours.checkboxBackground,
    borderRadius: "3px",
    transition: "all 150ms",
    cursor: "pointer",
  },
  styledCheckBoxChecked: {
    background: "transparent",
  },
  styledCheckBoxFocused: {
    boxShadow: `0 0 0 1px ${colours.selected}`,
  },
  checkBoxContainer: {
    display: "inline-block",
    verticalAlign: "middle",
  },
  icon: {
    width: "1.25rem",
    height: "1.25rem",
    color: colours.selected,
  },
  disabled: {
    background: colours.newLightGrey,
  },
};

const Checkbox = (props) => {
  const {
    label,
    handleCheckboxChange,
    checked,
    disabled,
    checkIcon,
    id,
    tabIndex,
  } = props;

  const { styles } = useStyles(baseStyles, props);
  const [isFocused, setIsFocused] = useState(false);

  const defaultCheckIcon = (
    <FontAwesomeIcon icon={faCheck} className={css(styles.icon)} />
  );

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleChange = useCallback(
    (event) => {
      if (handleCheckboxChange) {
        handleCheckboxChange({
          event: event.nativeEvent,
          label,
          checked,
        });
      }
    },
    [checked, handleCheckboxChange, label]
  );

  return (
    <label className={css(styles.label)} htmlFor={id}>
      <div className={css(styles.checkBoxContainer)}>
        <input
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          className={css(styles.hiddenCheckBox)}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
          id={id}
          data-testid={id}
        />
        <div
          className={css(
            styles.styledCheckBox,
            isFocused && styles.styledCheckBoxFocused,
            checked && styles.styledCheckBoxChecked,
            disabled && styles.disabled
          )}
          {...accessibleClickProps(handleChange)}
          role="checkbox"
          aria-checked={checked}
          tabIndex={tabIndex}
        >
          {checked && (checkIcon || defaultCheckIcon)}
        </div>
      </div>
      <span className={css(styles.label, checked && styles.labelActive)}>
        {label}
      </span>
    </label>
  );
};

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.string,
  handleCheckboxChange: PropTypes.func,
  checked: PropTypes.bool,
  checkIcon: PropTypes.node,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number,
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  tabIndex: 0,
};

export default memo(Checkbox);
